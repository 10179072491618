const translations = {
  routes: {
    index: '/',
    contact: '/kontakt',
    offer: '/oferta',
    news: '/wiadomosci',
    privacy: '/prywatnosc',
  },
  pages: {
    index: 'Strona Główna',
    contact: 'Kontakt',
    offer: 'Oferta',
    news: 'Wiadomości',
    privacy: 'Polityka prywatności',
  },
  mainPage: {
    sectionValues: {
      lineHeader: 'kim jesteśmy',
      header: 'Historia i innowacyjność',
      text: 'Wiedza, doświadczenie, rozwój - podstawy naszych wartości, którymi kierujemy się każdego dnia pomagając naszym Klientom w rozwoju ich biznesów.',
      itemHeaders: {
        experience: 'Doświadczenie',
        knowledge: 'Wiedza',
        development: 'Rozwój',
        safety: 'Bezpieczeństwo',
      },
      itemDescriptions: {
        experience:
          'Wykorzystując ponad 25 letnie doświadczenie z zakresu prawa podatkowego i księgowości świadczymy usługi na najwyższym poziomie.',
        knowledge:
          'Nieustająco zmieniające się prawo podatkowe i skarbowe jest na bieżąco analizowane przez wykwalifikowaną kadrę pracowniczą.',
        development:
          'W naszej pracy stawiamy na nieprzerwany postęp technologiczny, nowatorskie rozwiązania podatkowe i rozwój kadry pracowniczej.',
        safety:
          'Bezpieczeństwo danych osobych, kadrowych i finansowych naszych Klientów traktujemy priorytetowo.',
      },
    },
    sectionStaff: {
      lineHeader: 'zespół',
      header: 'Nasz zespół',
      text: 'Jako Kancelaria Podatkowa jesteśmy zgranym zespołem pracujących wspólnie od wielu lat pasjonatów księgowości. Stawiamy na ciągły rozwój i swobodne relacje. Tak, kochamy podatki!',
      team: 'Zespół',
    },
    sectionMission: {
      lineHeader: 'nasza misja',
      header: 'Twój biznes w dobrych rękach',
      text: 'W Kancelarii Michalak cenimy indywidualne i kompleksowe podejście do każdego z naszych Klientów. Zależy nam na uzyskaniu stałej, długotrwałej i owocnej współpracy z korzyścią dla biznesu naszych Partnerów.',
      missionItems: {
        care: {
          header: 'Indywidualna opieka',
          description:
            'Każdy z naszych Klientów może liczyć na indywidualną opiekę oddelegowanego specjalisty, odpowiedzialnego za wszelkie sprawy związane z działalnością naszych Klientów.',
        },
        complex: {
          header: 'Kompleksowość',
          description:
            'Główną ideą i celem naszej firmy jest wszechstronne i całościowe podejście do naszych Klientów - od opieki księgowej, zarządzania kadrami do przygotowywania sprawozdań finansowych.',
        },
        growth: {
          header: 'Rozwój biznesu',
          description:
            'W pracy nie ograniczamy się tylko i wyłącznie do prostego wprowadzania dokumentacji finansowej. Zależy nam na systematycznym rozwoju Klientów i ich firm, służąc pomocą i wsparciem podczas podejmowania decyzji biznesowych.',
        },
        advisory: {
          header: 'Doradztwo i pomoc',
          description:
            'Nawiązując długotrwałą współpracę z Kancelarią Michalak nasi Klienci mogą liczyć na stałe, bezpłatne doradztwo i pomoc z zakresu prawa podatkowego czy prawa pracy.',
        },
        prices: {
          header: 'Atrakcyjne stawki',
          description:
            'Zapewniamy wsparcie zarówno dla Klientów rozpoczynających działalność gospodarczą, jak i dla firm funkcjonujących obecnie na rynku. Każdorazowo dopasowujemy stawki do sytuacji Klienta.',
        },
        safety: {
          header: 'Bezpieczeństwo informacji',
          description:
            'Gwarantujemy, że pracujemy z zachowaniem zasad poufności oraz zgodnie z wymogami prawa polskiego i unijnego.',
        },
      },
    },
    sectionOffer: {
      lineHeader: 'co robimy',
      header: 'Pełen zakres usług',
      text: 'W Kancelarii Michalak oferujemy dla naszych Klientów szeroki wachlarz oferowanych produktów. Dzięki specjalistom prawa podatkowego i prawa pracy zapewniamy najwyższy poziom świadczonych usług. Kancelaria współpracuje z zewnętrznym doradcą podatkowym zapewniając Klientom pełną obsługę, zarówno księgową, jak i w zakresie doradztwa podatkowego.',
      sectionItems: {
        accounting: {
          header: 'Usługi księgowe',
          description:
            'Szeroki zakres usług księgowych i rachunkowych w tym m.in. prowadzenie ksiąg rachunkowych spółek kapitałowych czy podatkowej księgi przychodów i rozchodów.',
        },
        staff: {
          header: 'Kadry i płace',
          description:
            'Oferujemy kompleksową usługę z zakresu zarządzania kadrami, w ramach której prowadzimy całkowicie zintegrowany dział personalny, umożliwiając tym samym redukcję kosztów oraz korzystanie z pełnej palety obsługi w zakresie HR.',
        },
        business: {
          header: 'Zakładanie działalności gospodarczej',
          description:
            'Klientom mającym pomysł na biznes zapewniamy pomoc związaną z zakładaniem działalności gospodarczej. Pomoc w zakresie zawiłości spraw formalnych spoczywa na Kancelarii.',
        },
        companies: {
          header: 'Obsługa spółek kapitałowych',
          description:
            'Jako Kancelaria Michalak specjalizujemy się w obsłudze spółek kapitałowych, ich bieżącej działalności, jak również w procesach przekształcenia, łączenia i likwidacji działalności gospodarczych z odrębną osobowością prawną w ramach ścisłej współpracy z zewnętrznym doradcą podatkowym.',
        },
        advisory: {
          header: 'Doradztwo podatkowe',
          description:
            'Usługi z zakresu doradztwa podatkowego obejmują wszelkie aspekty funkcjonowania działalności gospodarczej, głównie w zakresie optymalizacji podatkowej i wsparcia firm podczas kontroli skarbowej.',
        },
        other: {
          header: 'Pozostałe usługi',
          description:
            'Stosując kompleksowe i indywidualne podejście do każdego Klienta, rozwiązujemy nawet najbardziej nietypowe problemy wykraczające poza standardowy zakres usług.',
        },
      },
    },
    sectionNews: {
      lineHeader: 'wiadomości',
      header: 'Bądź na bieżąco',
      text: 'Stały kontakt z obecnymi i potencjalnymi Klientami jest dla nas priorytetem. Zapewniamy najnowsze wiadomości podatkowe i biznesowy z dedykowanym komentarzem specjalisty,',
    },
    sectionContact: {
      lineHeader: 'kontakt',
      header: 'Poznajmy się',
      text: 'Stały kontakt z obecnymi i potencjalnymi Klientami jest dla nas priorytetem. Zapewniamy najnowsze wiadomości podatkowe i biznesowy z dedykowanym komentarzem specjalisty,',
      form: {
        header: 'Zacznijmy współpracę',
        description:
          'Zostaw swój numer telefonu, a skontaktujemy się z Tobą w ciągu najbliższych dni',
        button: 'Wyślij',
        ps1: 'Wypełnienie formularza oznacza wyrażenie zgody na przetwarzanie przez Michalak Kancelarię Podatkowo-Księgową Sp. z o.o. podanych w formularzu danych osobowych w celu udzielenia odpowiedzi na zadane pytanie i w zależności od treści zapytania przedstawienia oferty. ',
        ps2: 'Tutaj',
        ps3: 'dowiesz się kim jesteśmy i jak przetwarzamy Twoje dane.',
      },
    },
  },
  buttons: {},
  links: {
    facebook: 'https://www.facebook.com/michalakbiuro/',
    linkedin: 'http://www.michalakbiuro.pl',
    google: 'https://g.page/michalakbiuro?share',
    phone: 'tel:25-641-40-61',
  },
  headers: {
    index: {
      welcome: 'Witamy',
      accounting: 'Księgowość',
      advisory: 'Kadry',
      bookkeeping: 'Sprawozdawczość Finansowa',
      weWork: 'Pracujemy z',
      theBest: 'najlepszymi',
    },
    contact: {
      contact: 'Skontaktuj',
      us: 'się z nami.',
      begin: 'Rozpocznijmy',
      cooperation: 'współpracę',
    },
    news: {
      newest: 'Najnowsze',
      information: 'informacje',
      tax: 'podatkowe',
    },
    offer: {
      broad: 'Szeroki',
      area: 'zakres',
      services: 'usług',
    },
    privacy: {
      privacy: 'Polityka',
      policy: 'prywatności',
    },
  },
  UI: {
    more: 'więcej',
    readAll: 'Czytaj całość',
    readMore: 'Czytaj więcej',
    allNews: 'Wszystkie wiadomości',
    formSubmitted: 'Dziękujemy za wypełnienie formularza',
  },
  footer: '2021 Wszystkie prawa zastrzeżone',
  phone: '+25 641 40 61',
  findUs: 'Znajdź nas na mapie',
  descriptions: {
    contact:
      'Wypełnij poniższy formularz lub zadzwoń do nas - nasz pracownik skontaktuje się z Tobą niezwłocznie w celu umówienia spotkania w naszej siedzibie lub spotkania online.',
    news: 'Przedstawiamy najważniejsze, bieżące informacje ze świata podatków, prawa i ekonomii.',
    offer:
      'Kompleksowa oferta zawierająca pełen przekrój w działalności każdego przedsiębiorstwa. Kancelaria współpracuje z zewnętrznym doradcą podatkowym zapewniając Klientom pełną obsługę, zarówno księgową, jak i w zakresie doradztwa podatkowego. Wszystkie ceny świadczonych usług negocjowane są indywidualnie z naszymi Klientami.',
  },
  errors: {
    required: 'Uzupełnij wymagane pole',
    email: 'Wprowadź poprawny adres email',
    phone: 'Wprowadź poprawny numer telefonu',
  },
  contactPage: {
    openHours: 'Godziny otwarcia',
    workingDays: 'Poniedziałek - Piątek: 8.00 - 16.00',
  },
  required: '*Pola obowiązkowe',
  company: 'Michalak Kancelaria Podatkowo-Księgowa Sp. z o.o.',
  headquaters: 'Siedziba',
  adress: 'ul. Dylewicza 15E\n08-103 Siedlce\nPolska\nNIP: 821-264-08-20',
  minimalPrice: 'Cena już od ',
}

export default translations
